import 'bootstrap'
import '@hotwired/turbo-rails'
import './sharer'
import './controllers'

window.onload = function addMobilePopup() {
  // https://getbootstrap.com/docs/3.4/css/#grid-options
  let mobileSize = 768

  if(document.getElementsByClassName('mobile-hint-holder').length > 0 && window.innerWidth < mobileSize) {
    const popup = document.getElementsByClassName('mobile-hint-holder')[0]
    popup.style.transition = 'top 0.3s ease-out'

    const maximum = popup.offsetHeight

    document.addEventListener('scroll', function(e) {
      const jobadPos = document.getElementById('jobad').offsetTop
      const jobApplicationAnchorPos = document.getElementById('job_application_anchor').offsetTop
      const scrollHeight = window.scrollY

      if(jobadPos < scrollHeight && scrollHeight < jobApplicationAnchorPos) {
        popup.style.top = window.innerHeight - maximum + "px"
        popup.style.opacity = '1'
      } else {
        popup.style.top = window.innerHeight + "px"
      }
    })

    addEventListener('resize', (event) => {
      if(jobadPos < scrollHeight && scrollHeight < jobApplicationAnchorPos) {
        popup.style.top = window.innerHeight - maximum + "px"
        popup.style.opacity = '1'
      } else {
        popup.style.top = window.innerHeight + "px"
      }
    })
  }
}

// the print button only exists if the html_url is from jobads
const printButton = document.querySelector('#print-frame')
printButton?.addEventListener('click', () => {
  document.getElementById('jobad').contentWindow.postMessage({ function: 'print' }, '*')
})

const shareButton = document.querySelector('#native-share')
shareButton?.addEventListener('click', () => {
  let fallback_selector = '#sharers'

  if (navigator.share) {
    navigator.share({
      title: 'Neuer Job!',
      text: 'Dieses Stellenangebot habe ich auf ' + window.location.host + ' gefunden!',
      url: window.location.href
    }).then(() => {
      console && console.log && console.log('Successful share')
    }).catch((error) => {
      console && console.error && console.error('Error sharing', error)
    })
  } else {
    let sharer = document.querySelector(fallback_selector)
    sharer.style.display = sharer.style.display == 'none' ? '' : 'none'
  }
})
